<template>
  <div>
    <v-card
      elevation="2"
      :disabled="isDisabled"
    >
      <v-card-title>
        {{ $t('customerInformation') }}
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          :label="$t('customer')"
          v-model="customer"
          :items="customers"
          item-value="uuid"
          item-text="address.name_line"
          return-object
          @change="selectCustomer"
          :search-input.sync="customerSearch"
          :filter="customerFilter"
        >
          <template v-slot:no-data>
            <v-container>
              <div class="text--secondary text-center">
                <div class="pb-3">
                  {{ $t('noCustomerWithThatNameFound') }}
                </div>
                <div>
                  <v-btn
                    block
                    :color="primaryColor"
                    :class="textColor(primaryColor)"
                    @click="createCustomer"
                  >
                    {{ $t('createCustomer') }}
                  </v-btn>
                </div>
              </div>
            </v-container>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data !== 'object'">
              <v-list-item-content v-text="data.item" />
            </template>
            <template v-else>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.address.name_line }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <div>{{ data.item.email }}</div>
                  <div>{{ data.item.phone }}</div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
        <v-form v-model="formFilled">
          <v-text-field
            :label="$t('email') + ' *'"
            v-model="customerEmail"
            :rules="[rules.required]"
            :disabled="hasInputData('email')"
          />
          <v-text-field
            :label="$t('phoneNumber') + ' *'"
            v-model="customerPhone"
            :rules="[rules.required]"
            :disabled="hasInputData('phone')"
          />
        </v-form>
      </v-card-text>
    </v-card>
    <CustomerDialog
      :key="showCreateCustomer"
      v-model="customer"
      :dialog="showCreateCustomer"
      @updated="customerUpdated"
      @created="customerCreated"
      @dialog="customerDialogChanged"
    />
  </div>
</template>

<script>
	import CustomerDialog from "../CustomerDialog"

	export default {
		name: "CustomerSelection",
		components: {
			CustomerDialog
		},
		props: {
			type: {
				type: String,
				default: ''
			},
			isDisabled: {
				type: Boolean
			}
		},
		data() {
			return {
				formFilled: false,
				processing: false,
				customer: null,
				customerSearch: null,
				customerUUID: null,
				customerName: null,
				customerEmail: null,
				customerPhone: null,
				showCreateCustomer: false,
			}
		},
		methods: {
			selectCustomer(customer) {
				this.customer = customer
				this.$emit('input', customer.uuid)
				if(customer) {
					this.customerPhone = customer.phone
					this.customerEmail = customer.email
				} else {
					this.customerPhone = null
					this.customerEmail = null
				}
			},
			customerFilter(item, queryText, itemText) {
				if(itemText.toLowerCase().includes(queryText.toLowerCase())) {
					return itemText
				}
				if(item && item.phone && item.phone.toString().toLowerCase().includes(queryText.toString().toLowerCase())) {
					return itemText
				}
				if(item && item.email && item.email.toLowerCase().includes(queryText.toLowerCase())) {
					return itemText
				}
			},
			createCustomer() {
				this.showCreateCustomer = !this.showCreateCustomer
			},
			textColor(color) {
				return this.$store.getters.textButtonColor(color)
			},
			customerCreated(customer) {
				this.showCreateCustomer = false
				this.customer = this.$store.getters.getCustomerByUUID(customer.uuid)
			},
			customerUpdated(customer) {
				this.showCreateCustomer = false
				this.customer = this.$store.getters.getCustomerByUUID(customer.uuid)
			},
			customerDialogChanged(dialog) {
				this.showCreateCustomer = dialog
			},
			hasInputData(input) {
				let customer = this.customer
				switch(input) {
					case 'name':
						return !!(customer && customer.address && customer.address.name_line)
					case 'phone':
						return !!(customer && customer.phone)
					case 'email':
						return !!(customer && customer.email)
					default:
						return false
				}
			},
		},
		computed: {
			primaryColor() {
				return this.$store.getters.primaryColor
			},
			rules() {
				return this.$store.getters.rules
			},
			customers() {
				return this.$store.state.customers
			}
		},
		watch: {
			formFilled(value) {
				this.$emit('formFilled', value)
			},
			customer(value) {
				if(value) {
					this.customerPhone = value.phone
					this.customerEmail = value.email
				} else {
					this.customerPhone = null
					this.customerEmail = null
				}
			}
		},
		mounted() {
			console.log(this.customers)
		}
	}
</script>

<style scoped>

</style>